import { BarceloComponent } from "barcelo-components";

const ListingComponents = {
  feedEntries: BarceloComponent({ selector: ".c-feed-entries", path: "listing/feed-entries/js/c-feed-entries" }),
  cardHotelList: BarceloComponent({
    selector: ".c-card-hotel-list__content",
    path: "listing/card-hotel-list/js/card-hotel-list",
    skipInit: true,
  }),
  offerDestination: BarceloComponent({ selector: ".JS-offers-destination", path: "listing/offers-destination/js/c-offers-destination" }),
  promoList: BarceloComponent({ selector: ".c-promo-list", path: "listing/promo-list/js/promo-list" }),
  campaignList: BarceloComponent({ selector: ".campaign-list", path: "listing/campaign-list/js/c-campaign-list" }),
};

export default ListingComponents;
