import { BarceloComponent } from "barcelo-components";

const TeasersComponents = {
  carousel: BarceloComponent({ selector: ".cmp-bhg-carousel", path: "teasers/carousel/js/c-carousel" }),
  coupon: BarceloComponent({ selector: ".coupon-cta-JS", path: "teasers/coupon/js/c-coupon" }),
  servicesCarousel: BarceloComponent({ selector: ".c-services-carousel", path: "teasers/services-carousel-cards/js/c-services-carousel-cards" }),
  card: BarceloComponent({ selector: [".text-desktop-JS", ".text-mobile-JS"], path: "teasers/card/js/c-card" }),
  launcher: BarceloComponent({ selector: ".JS-c-launcher-link", path: "teasers/launcher/js/c-launcher" }),
};

export default TeasersComponents;
