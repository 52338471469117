// NECESSARY FOR FASTBOOKING (HIGHEST PRIORITY)
import "./js/shared/tabsContainer.js";
import "./js/shared/loadForm.js";

// Init Components and Shared dynamically
import BaseComponents from "./components";
import BarceloComponents from "barcelo-components-init";
import { BarceloShared } from "barcelo-shared-init";

document.addEventListener("DOMContentLoaded", async () => {
  // Load BhgComponents dynamically
  const styleSheets = {
    ".hotel-royal-level": "/etc.clientlibs/barcelo/clientlibs/clientlib-hotelRoyalLevel.css",
    ".c-popover": "/etc.clientlibs/barcelo/clientlibs/clientlib-popover.css",
    ".c-hightlights-services-banner": "/etc.clientlibs/barcelo/clientlibs/clientlib-hightlightServiceBanner.css",
    ".c-hotel-card": "/etc.clientlibs/barcelo/clientlibs/clientlib-hotelCard.css",
    ".mod--horizontal-card-compact": "/etc.clientlibs/barcelo/clientlibs/clientlib-hotelHorizontalCompact.css",
    ".c-meeting__table, .c-meeting__modal, .meeting__table": "/etc.clientlibs/barcelo/clientlibs/clientlib-meetingTable.css",
    ".bhg-loading, .loading-dots": "/etc.clientlibs/barcelo/clientlibs/clientlib-loadings.css",
    ".c-flip-card": "/etc.clientlibs/barcelo/clientlibs/clientlib-flipCard.css",
    ".c-card-hotel, .c-card-hotel-list, .c-label--tag-all-included": "/etc.clientlibs/barcelo/clientlibs/clientlib-cardHotel.css",
    ".cmp-breadcrumb, .breadcrumb": "/etc.clientlibs/barcelo/clientlibs/clientlib-breadcrumb.css",
    ".c-textimage, .textimage": "/etc.clientlibs/barcelo/clientlibs/clientlib-textImage.css",
    ".c-label": "/etc.clientlibs/barcelo/clientlibs/clientlib-label.css",
    ".c-submenu-container": "/etc.clientlibs/barcelo/clientlibs/clientlib-submenuContainer.css",
    ".c-card-hotel-list__content": "/etc.clientlibs/barcelo/clientlibs/clientlib-cardHotelList.css",
    ".info-service__wrapper, .c-info-service": "/etc.clientlibs/barcelo/clientlibs/clientlib-infoServicePage.css",
    ".c-v-menu": "/etc.clientlibs/barcelo/clientlibs/clientlib-vMenu.css",
    ".meetings-comparator": "/etc.clientlibs/barcelo/clientlibs/clientlib-cardHotelCompactMeeting.css",
    ".offer-collection-list, .meetings-comparator": "/etc.clientlibs/barcelo/clientlibs/clientlib-offerCollectionList.css",
    ".c-collection-list": "/etc.clientlibs/barcelo/clientlibs/clientlib-collectionList.css",
  };

  const loadedStyles = new Set();

  const loadStyleSheetIfNeeded = function (className) {
    const styleSheet = styleSheets[className];
    if (styleSheet && !loadedStyles.has(className) && document.querySelector(`${className}`)) {
      bcl.u.loadStyleSheet(styleSheet, className);
      loadedStyles.add(className);
    }
  };

  Object.keys(styleSheets).forEach(function (className) {
    loadStyleSheetIfNeeded(className);
  });

  const observer = new MutationObserver(function () {
    Object.keys(styleSheets).forEach(function (className) {
      loadStyleSheetIfNeeded(className);
    });
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });

  await BarceloComponents.init({ ...BaseComponents });
  BarceloShared.init();
  window.dispatchEvent(new Event("bcl-base-components-loaded"));
});

// BARCELO";
import "./js/utils/cookieUtils.js";
import "./js/utils/utag.js";
import "./js/general.js";

//EMBED CODE
import "../../components/bhg/accordion";
import "../../components/bhg/tabs";

// Shared JS";
import "./js/shared/modal.js";
import "./js/shared/modalMeetingRoom.js";
import "./js/shared/sticky.js";
import "./js/shared/profile.js";
import "./js/shared/progressBar.js";
import "./js/shared/translate-on-the-fly.js";

//js my barcelo
import "../../components/barcelo/mybarcelo/myb-edit-profile/js/c-myb-edit-profile.js";
import "../../components/barcelo/mybarcelo/myb-trigger-banner/js/c-myb-trigger-banner.js";

import "./js/shared/countdown.js";
import "./js/shared/promo-countdown.js";
import "./js/shared/currency.js";
import "./js/shared/dataAnchor.js";
import "./js/shared/hotelReturn.js";
import "./js/shared/scrollUp.js";
import "./js/shared/searchHotel.js";
import "./js/shared/tabs.js";
import "./js/shared/recaptchav3.js";

// Shared JS with use components";
import "./js/shared/tooltip.js";
import "./js/shared/rte-tooltip.js";
import "./js/shared/360.js";
import "./js/shared/flipcard.js";
import "./js/shared/globalEvent.js";

// VENDORS";
import "./libraries/datepicker/fecha.min.js";
import "./libraries/datepicker/hotel-datepicker.js";
